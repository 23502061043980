import React from "react"
import Box from "@mui/material/Box"
import { Container } from "@mui/system"
import { Grid, Link, Typography } from "@mui/material"
import footer from "../../assets/images/footer.png"
import logo from "../../assets/images/logo.svg"
import MailIcon from "@mui/icons-material/Mail"
import PhoneIcon from "@mui/icons-material/Phone"
import LocationOnIcon from "@mui/icons-material/LocationOn"
import LinkedInIcon from "@mui/icons-material/LinkedIn"

function Footer() {
	return (
		<>
			<Box
				id="contacts"
				sx={{
					backgroundImage: `url(${footer})`,
					backgroundRepeat: "no-repeat",
					backgroundColor: "#1C1E20",
					backgroundPosition: "center",
					width: "100%",
					backgroundSize: "cover",
				}}
			>
				<Container sx={{ maxWidth: "1290px" }}>
					<Grid
						container
						sx={{ alignItems: { xs: "flex-start", md: "inherit" } }}
					>
						<Grid
							item
							xs={12}
							md={3}
							sx={{
								padding: "0px",
								display: "flex",
								margin: { xs: "40px 0 20px 0", md: "80px 0" },
								flexDirection: "column",
								alignItems: { xs: "flex-start" },
								justifyContent: { xs: "flex-start" },
							}}
						>
							<Box sx={{ width: { xs: "inherit", md: "100%" } }}>
								<img
									src={logo}
									alt="logo"
									style={{
										height: "57.64px",
										// width: "100%",
									}}
								/>
							</Box>
						</Grid>
						<Grid
							item
							xs={12}
							md={3}
							sx={{
								padding: "0px",
								display: "flex",
								margin: { xs: "20px 0", md: "80px 0" },
								flexDirection: "column",
								alignItems: { xs: "flex-start" },
								justifyContent: { xs: "flex-start" },
							}}
						>
							<Typography
								variant="body2"
								color="#fff"
								sx={{
									fontSize: "18px",
									lineHeight: "25.2px",
									fontWeight: "400",
									display: "flex",
									alignItems: "center",
								}}
							>
								VAPOUR OÜ
							</Typography>
							<Typography
								variant="body2"
								color="#fff"
								sx={{
									fontSize: "18px",
									lineHeight: "25.2px",
									fontWeight: "400",
									display: "flex",
									alignItems: "center",
								}}
							>
								Tallinn, Estonia
							</Typography>
							<Typography
								variant="body2"
								color="#fff"
								sx={{
									fontSize: "18px",
									lineHeight: "25.2px",
									fontWeight: "400",
									display: "flex",
									alignItems: "center",
								}}
							>
								Registry code: 14047933
							</Typography>
							<Typography
								variant="body2"
								color="#fff"
								sx={{
									fontSize: "18px",
									lineHeight: "25.2px",
									fontWeight: "400",
									display: "flex",
									alignItems: "center",
								}}
							>
								VAT: EE102193721
							</Typography>
						</Grid>
						<Grid
							item
							xs={12}
							md={3}
							sx={{
								padding: "0px",
								display: "flex",
								margin: { xs: "20px 0", md: "80px 0" },
								flexDirection: "column",
								alignItems: { xs: "flex-start" },
								justifyContent: { xs: "flex-start" },
							}}
						>
							<Link
								href="mailto:info@vapour.ee"
								sx={{ textDecoration: "none" }}
							>
								<Typography
									variant="body2"
									color="#fff"
									sx={{
										fontSize: "18px",
										lineHeight: "25.2px",
										fontWeight: "400",
										display: "flex",
										alignItems: "center",
										marginBottom: "15px",
									}}
								>
									<MailIcon
										style={{
											color: "#ADCADE",
											width: "18.33px",
											marginRight: "15px",
										}}
									/>
									info{"\u0040"}vapour.ee
								</Typography>
							</Link>
							<Link href="tel:+37251234567" sx={{ textDecoration: "none" }}>
								<Typography
									variant="body2"
									color="#fff"
									sx={{
										fontSize: "18px",
										lineHeight: "25.2px",
										fontWeight: "400",
										display: "flex",
										alignItems: "center",
										marginBottom: "15px",
									}}
								>
									<PhoneIcon
										style={{
											color: "#ADCADE",
											width: "18.33px",
											marginRight: "15px",
										}}
									/>
									+372 51234567
								</Typography>
							</Link>
							<Typography
								variant="body2"
								color="#fff"
								sx={{
									fontSize: "18px",
									lineHeight: "25.2px",
									fontWeight: "400",
									display: "flex",
									alignItems: "center",
									marginBottom: "15px",
								}}
							>
								<LocationOnIcon
									style={{
										color: "#ADCADE",
										width: "18.33px",
										marginRight: "15px",
									}}
								/>
								Kadaka 3/2, room 203, 10621 Tallinn
							</Typography>
							<Link
								sx={{ textDecoration: "none" }}
								target="_blank"
								href="https://www.linkedin.com/company/vapour-ltd/"
							>
								<Typography
									variant="body2"
									color="#fff"
									sx={{
										fontSize: "18px",
										lineHeight: "25.2px",
										fontWeight: "400",
										display: "flex",
										alignItems: "center",
										marginBottom: "15px",
									}}
								>
									<LinkedInIcon
										style={{
											color: "#ADCADE",
											width: "18.33px",
											marginRight: "15px",
										}}
									/>
									LinkedIn
								</Typography>
							</Link>
						</Grid>
						<Grid
							item
							xs={12}
							md={3}
							sx={{
								padding: "0px",
								display: "flex",
								margin: { xs: "20px 0", md: 0 },
								flexDirection: "column",
								alignItems: { xs: "center" },
								justifyContent: { xs: "center" },
							}}
						>
							{/* <Box sx={{ margin: { xs: 0, md: "20px 0" } }}> */}
							<iframe
								src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2030.0837456125225!2d24.680263977266627!3d59.41499797465591!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4692945906632fa1%3A0x853f6c0a1df524ea!2sKadaka%20tee%203%2C%2010621%20Tallinn!5e0!3m2!1sru!2see!4v1672670769356!5m2!1sru!2see"
								height="260"
								title="map"
								width="100%"
								style={{ border: "0", margin: "0" }}
								allowfullscreen=""
								loading="lazy"
								referrerpolicy="no-referrer-when-downgrade"
							></iframe>
							{/* </Box> */}
						</Grid>
					</Grid>
					<Grid
						container
						sx={{ alignItems: { xs: "flex-start", md: "inherit" } }}
					>
						<Grid
							item
							xs={12}
							sx={{
								padding: "0px",
								display: "flex",
								margin: { xs: "20px 0", md: "0 0 20px 0" },
								alignItems: "center",
								justifyContent: "center",
								color: "#F6F9FB80",
							}}
						>
							Copyright 2022 Vapour OÜ All right reserved
						</Grid>
					</Grid>
				</Container>
			</Box>
		</>
	)
}
export default Footer
